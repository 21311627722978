import { Button, Text } from '@pancakeswap-libs/uikit'
import React, { useCallback, } from 'react'
import styled from 'styled-components'
import Modal from '../Modal'
import { AutoRow, RowBetween } from '../Row'
import { AutoColumn } from '../Column'
import useI18n from "../../hooks/useI18n";


const WarningContainer = styled.div`
  max-width: 420px;
  width: 100%;
  padding: 1rem;
  background: rgba(242, 150, 2, 0.05);
  border: 1px solid #f3841e;
  border-radius: 20px;
  overflow: auto;
`

export default function TipWarningModal({
                                              isOpen,
                                              onConfirm,
                                          }: {
    isOpen: boolean
    onConfirm: (isBoolean:boolean) => void
}) {
    const TranslateString = useI18n()
    const handleDismiss = useCallback(() => null, [])
    return (
        <Modal isOpen={isOpen} onDismiss={handleDismiss} maxHeight={90}>
            <WarningContainer className="token-warning-container">
                <AutoColumn gap="lg">
                    <AutoRow gap="6px">
                        <Text color="failure">{TranslateString(123,'Kind tips')}</Text>
                    </AutoRow>
                    <Text>
                        {TranslateString(124,
                            'The access authority of the current network environment is restricted, and we cannot provide services to mainland China, the United States, Singapore and other regions. Please follow the relevant regulations of your country and region to access our services.'
                        )}
                    </Text>
                    <Text>
                        {TranslateString(125,
                            'Unstable access to the network due to compliance issues in the country and region where the user is located is force majeure and has nothing to do with the platform. Transaction losses caused by such reasons are not within the scope of compensation.'
                        )}
                    </Text>
                    <RowBetween>
                        <Button
                            variant="primary"
                            style={{ width: '100%' }}
                            className="token-dismiss-button"
                            onClick={() => {
                                onConfirm(false)
                            }}
                        >
                            {TranslateString(127,'I see')}
                        </Button>
                    </RowBetween>
                </AutoColumn>
            </WarningContainer>
        </Modal>
    )
}
