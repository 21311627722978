export const lan_ids = [1138, 116, 1140, 1142, 262, 292, 132, 1196, 256, 258, 260, 264, 266, 1200, 1202, 1144, 1146, 1148, 88, 186, 1150, 90, 188, 1204, 82, 128, 126, 180, 1152, 1206, 1208, 999,
    1210, 220, 202, 224, 228, 230, 999, 1210, 220, 202, 226, 224, 228, 999, 116, 1128, 1130, 1132, 1134, 148, 150, 250, 252, 1154, 1156, 1158, 1160, 1162, 1164, 1166, 262, 1168, 168, 107, 1170, 156, 104, 106, 108, 1172, 1174, 208,
    82, 210, 212, 168, 214, 136, 1182, 1136, 1156, 1184, 1186, 166, 1188, 1190, 8, 1192, 194, 76, 196, 80, 1182, 88, 1194, 136, 84,
    10000, 10001, 10002, 10003, 10004, 10005, 10006, 10007, 10008, 10009, 10010, 10011, 10012, 10013, 10014, 10015, 10016,
    10017, 10018, 10019, 10020, 10021, 10022, 10023, 10024, 10025, 10026, 10027, 10028, 10029, 10030, 10031, 10032, 10033,
    10034, 10035, 10036, 10037, 10038, 10039, 10040, 10041, 10042,
    123,124,125,127
]
export const lan_ens = ['Recipient', '(View on OMPScan)', 'Wallet Address or ENS name', 'Swap', 'Liquidity', 'Unlock Wallet', 'Input', 'Select a currency', 'Use this tool to find pairs that do not automatically appear in the interface.',
    'Add', 'Remove', 'When you add liquidity, you are given pool tokens representing your position. These tokens automatically earn fees proportional to your share of the pool, and can be redeemed at any time.',
    'Removing pool tokens converts your position back into underlying tokens at the current rate, proportional to your share of the pool. Accrued fees are included in the amounts you receive.',
    'Settings', 'Recent transactions', 'Enter a valid slippage percentage', 'Your transaction may fail', 'Your transaction may be frontrun', 'Slippage tolerance',
    'Your transaction will revert if the price changes unfavorably by more than this percentage.', 'Enter a valid deadline', 'Transaction deadline', 'Your transaction will revert if it is pending for more than this long.',
    'These tokens are commonly paired with other tokens.', 'Select a token', 'Find a token by searching for its name or symbol or by pasting its address below.', 'Token name', 'Change', 'Select a list', 'View list',
    'Manage Lists', 'Token lists are an open specification for lists of OMP20 tokens. You can use any token list by entering its URL below. Beware that third party token lists can contain fake or malicious OMP20 tokens.',
    'Minimum received', 'Maximum sold', 'Your transaction will revert if there is a large, unfavorable price movement before it is confirmed.', 'The difference between the market price and estimated price due to trade size.',
    'Liquidity Provider Fee', 'For each trade a 0.2% fee is paid. 0.17% goes to liquidity providers and 0.03% goes to the TrueSwap treasury.', 'Routing through these tokens resulted in the best price for your trade.',
    'Minimum received', 'Maximum sold', 'Your transaction will revert if there is a large, unfavorable price movement before it is confirmed.', 'Price Impact',
    'The difference between the market price and your price due to trade size.', 'Liquidity Provider Fee', 'For each trade a 0.2% fee is paid. 0.17% goes to liquidity providers and 0.03% goes to the TrueSwap treasury.',
    '(View on OmpScan)', 'Token imported', 'Anyone can create a OMP20 token on OMP with any name, including creating fake versions of existing tokens and tokens that claim to represent projects that do not have a token.',
    'This interface can load arbitrary tokens by token addresses. Please take extra caution and do your research when interacting with arbitrary OMP20 tokens.', 'If you purchase an arbitrary token, you may be unable to sell it back.',
    'I understand', 'Continue', 'Create Pool & Supply', 'Confirm Supply', 'You are creating a pool', 'You will receive', 'You are the first liquidity provider.', 'The ratio of tokens you add will set the price of this pool.',
    'Once you are happy with the rate click supply to review.', 'Initial prices and pool share', 'Prices and pool share', 'Liquidity', 'Add liquidity to receive LP tokens', 'Add Liquidity', 'Your Liquidity',
    'When you add liquidity, you are given pool tokens that represent your share. If you don’t see a pool you joined in this list, try importing a pool below.', 'Connect to a wallet to view your liquidity.',
    'No liquidity found.', "Don't see a pool you joined?", 'Import it.', 'Or, if you staked your LP tokens in a farm, unstake them to see them here.', 'Connect to a wallet to find pools', 'Select a token to find your liquidity.',
    'Select a Token', 'Pool found!', 'You don’t have liquidity in this pool yet.', 'Add Liquidity', 'No pool found.', 'Invalid pair.', 'Price', 'Confirm', 'You will receive', 'Simple', 'Detailed', 'Max', 'Receive WOMP', 'Receive OMP',
    'Exchange', 'Trade tokens in an instant', 'From (estimated)', 'From', 'To (estimated)', 'To', 'Price', 'Slippage Tolerance', 'Insufficient liquidity for this trade.', 'Invalid pair', 'Enter an amount',
    'Enter a recipient', 'Invalid recipient', 'Connect Wallet', 'Price Impact Too High', 'Minutes', 'No recent transactions', 'Close',
    'Balance', 'per', 'Share of Pool', 'Search name or paste address', 'Added by user', 'Import Pool', 'Found by address', 'Create pool.',
    'Confirm Swap', 'Swap Anyway', 'Output is estimated. If the price changes by more than', 'your transaction will revert.',
    'Output is estimated. You will receive at least', ' or the transaction will revert.', 'Input is estimated. You will sell at most',
    'Output will be sent to', 'Price Updated', 'Accept', 'Waiting for confirmation', 'Confirm this transaction in your wallet', 'Swapping',
    'for', 'Transaction rejected.', 'Swap failed', 'to', 'This transaction will not succeed either due to price movement or fee on transfer. Try increasing your slippage tolerance.',
    'Unexpected issue with estimating the gas. Please try again.', 'Unexpected error. Please contact support: none of the calls threw an error',
    'Error', 'Dismiss', 'LP Tokens in your Wallet', 'True Home', 'Please connect your wallet to view your recent transactions', 'Pooled',
    'Your pool tokens:', 'Your pool share:','Kind tips','The access authority of the current network environment is restricted, and we cannot provide services to mainland China, the United States, Singapore and other regions. Please follow the relevant regulations of your country and region to access our services.','Unstable access to the network due to compliance issues in the country and region where the user is located is force majeure and has nothing to do with the platform. Transaction losses caused by such reasons are not within the scope of compensation.','I see'
]
export const lan_zhs = ['接受者', '（在 OMPScan 上查看）', '钱包地址或 ENS 名称', '交换', '流动性', '解锁钱包', '输入', '选择货币', '使用此工具查找不会自动出现在界面中的对。',
    '添加', '移除', '当您增加流动性时，您将获得代表您头寸的池代币。 这些代币会自动赚取与您在池中的份额成比例的费用，并且可以随时赎回。',
    '移除池代币会将您的头寸以当前比率转换回基础代币，与您在池中的份额成正比。 应计费用包含在您收到的金额中。',
    '设置', '最近的交易', '输入有效的滑点百分比', '您的交易可能失败', '您的交易可能是抢先交易', '滑点容忍度',
    '如果价格的不利变化超过此百分比，您的交易将恢复。', '输入有效的截止日期', '交易截止日期', '如果等待时间超过此时间，您的交易将恢复。',
    '这些令牌通常与其他令牌配对。', '选择一个令牌', '通过搜索其名称或符号或在下面粘贴其地址来查找令牌。', '令牌名称', '更改', '选择一个 list', '查看列表',
    '管理列表', '令牌列表是 OMP20 令牌列表的开放规范。 您可以通过在下面输入其 URL 来使用任何令牌列表。 请注意第三方代币列表可能包含虚假或恶意的 OMP20 代币。',
    '收到的最低金额', '卖出的最高金额', '如果在确认之前出现较大的不利价格变动，您的交易将恢复。', '由于交易规模而导致的市场价格与估计价格之间的差异。',
    '流动性提供者费用', '每笔交易支付 0.2% 的费用。 0.17% 用于流动性提供者，0.03% 用于 TrueSwap 国库。', '通过这些代币进行路由会为您的交易带来最优惠的价格。',
    '最低收到', '最高卖出', '如果在确认之前出现大的不利价格变动，您的交易将恢复。', '价格影响',
    '市场价格与您的价格因交易规模而产生的差异。', '流动性提供者费用', '每笔交易支付 0.2% 的费用。 0.17% 归流动性提供者，0.03% 归 TrueSwap 国库。',
    '(在 OmpScan 上查看)', '令牌导入', '任何人都可以在 OMP 上以任何名称创建 OMP20 令牌，包括创建现有令牌的假版本和声称代表没有令牌的项目的令牌。',
    '这个接口可以通过令牌地址加载任意令牌。 与任意 OMP20 代币交互时请格外小心并进行研究。', '如果您购买任意代币，您可能无法将其卖回。',
    '我明白', '继续', '创建池和供应', '确认供应', '你正在创建一个池', '你将收到', '你是第一个流动性提供者', '代币的比例 您添加将设置此池的价格。',
    '一旦您对价格感到满意，请点击供应查看。', '初始价格和池份额', '价格和池份额', '流动性', '添加流动性以接收 LP 代币', '添加流动性', '您的 流动性',
    '当你增加流动性时，你会得到代表你份额的池代币。 如果您在此列表中没有看到您加入的池，请尝试在下面导入池。', '连接到钱包以查看您的流动性。',
    '没有找到流动性。', '没有看到您加入的矿池？', '导入它。', '或者，如果您将 LP 代币抵押在农场，请取消抵押以在此处查看它们。', '连接到 一个寻找矿池的钱包', '选择一个代币来找到你的流动性。',
    '选择一个代币', '找到池！', '你在这个池中还没有流动性。', '添加流动性', '没有找到池。', '无效对。', '价格', '确认 ', '您将收到', '简单', '详细', '最大限度', '接收 WOMP', '接收 OMP',
    '交易所', '立即交易代币', '从（估计）', '从', '至（估计）', '至', '价格', '滑点公差', '此交易的流动性不足。 ', '无效对', '输入金额',
    '输入收件人', '收件人无效', '连接钱包', '价格影响太高', '分钟', '近期无交易', '关闭', '余额', '每', '池份额', '搜索名称或粘贴地址',
    '用户添加', '导入池', '按地址查找到', '创建池子。', '确认交换', '无论如何兑换', '估计产量。如果价格变化超过', '您的交易将恢复',
    '估计产量。你至少会收到', '否则交易将恢复', '估计输入。你最多只能卖', '输出将发送到', '价格更新', '同意', '等待确认',
    '在您的钱包中确认此交易', '正在兑换', '为', '交易被拒绝。', '兑换失败', '到', '由于价格变动或转让费用，此交易将不会成功。尝试增加你的滑点。',
    '估计气体时出现意外问题，请重试。', '意外错误，请于支持人员联系：所有呼叫均未引发错误', '错误', '关闭', '钱包中的LP代币', 'True首页',
    '请连接您的钱包以查看您最近的交易', '池子中的', '池子LP值:','池子份额占比','温馨提示','当前网络环境访问权限受到限制，我们无法向中国大陆、美国、新加坡等地区提供服务，请按照您所在国家和地区的相关规定访问我们的服务。','因用户所在国家和地区合规问题导致网络访问不稳定属于不可抗力，与平台无关。此类原因造成的交易损失不在赔偿范围内。','我明白了'
]
